import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';
import queryString from 'querystring';
import _ from 'lodash';

class FormDespacho extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      fordesCodigo: null,
      pedidoCodigo: null,
      vehiculoCodigoViaje: null,
      terceroCodigoViaje: null,
      terceroCodigoAuxiliar: null,
      vehiculoCodigoCargue: null,
      terceroCodigoCargue: null,
      destinatarioCodigo: null,
      fordesFlete: 0,
      fordesAnticipo: 0,
      fordesConsecutivoManual: null,
      fordesManifiesto: null,
      fordesFactura: null,
      ordcarCodigo: null,
      manifiestoCodigo: null,
      fordesObservacion: null,
      fordesFechaDescargue:null,
      fordesPeso: 0,
      facturaCodigo: null,
      fordesRut:null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    fieldsConsulta:{fordesClienteDocumento:null},
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['pedidoCodigo', 'fordesManifiesto', 'fordesFactura', 'vehiculoCodigoCargue', 'terceroCodigoCargue', 'fordesConsecutivoManual', 'fordesRut']
    },
    formErrors: {
      pedidoCodigo: {},
      fordesManifiesto: {},
      fordesFactura: {},
      vehiculoCodigoCargue: {},
      terceroCodigoCargue: {},
      fordesConsecutivoManual: {},
      fordesRut:{}
    }
  };

  async componentDidMount() {
    const params = this.props.match.params;

    const query = queryString.parse(this.props.location.search.replace('?', ''));
    if (_.keys(query).length && !params.id) {
      let state = this.state.fields;
      state["pedidoCodigo"] = parseInt(query.pedidoCodigo);

      const clienteDocumento = await this.getClienteDocumento(parseInt(query.pedidoCodigo));

      this.setState({
        fields: state,
        fieldsConsulta:{fordesClienteDocumento:clienteDocumento}
      });

      return;
    } else if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'fordesCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  getClienteDocumento = async (id) => {
    const document = await getDocument({
        documentId: id,
        fieldName: 'pedidoCodigo',
        moduleName: 'Pedido'
    });

    if (!document) {
        return;
    }
    return document.pedidoContactoNumeroDocumento;
};

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = async (event) => {
    let { fields, fieldsConsulta } = this.state;

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    if (event.target.id === 'vehiculoCodigoCargue') {
      const clienteDocumento = await this.getClienteDocumento(parseInt(this.state.fields.pedidoCodigo));
      fieldsConsulta["fordesClienteDocumento"] = clienteDocumento;
    }

    this.setState({ fields,fieldsConsulta });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    if (parseFloat(this.state.fields.pedidoTotal) <= 0 ||
      (parseFloat(this.state.fields.pedidoTotal) < parseFloat(this.state.fields.pedidoValorServicios)) ||
      (parseFloat(this.state.fields.pedidoTotal) < parseFloat(this.state.fields.pedidoValorPrincipal)) ||
      (parseFloat(this.state.fields.pedidoTotal) < (parseFloat(this.state.fields.pedidoValorServicios) + parseFloat(this.state.fields.pedidoValorServicios)))
    ) {
      message = "El valor total no es correcto. Grabe el registro nuevamente.";
      isvalid = false;
    }

    if(this.state.fields.fordesManifiesto==='SI' && this.state.fields.fordesPeso === 0){
      message = "Verifique el valor del peso.";
      isvalid = false;
    }

    if ((this.state.fields.ordcarCodigo !== null)) {
      message = "Lo sentimos mucho, este modulo no permite actualizaciones.";
      isvalid = false;
      type = ""
    }

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  getEditUrl = (id, module) => {
    return `/home/${module}/${id}/edit`;
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="fordesCodigo"
              className={this.props.classes.tittleLabelForm}>Despacho
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.fordesCodigo !== null) ?
                (<Chip
                  label={this.state.fields["fordesCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id='pedidoCodigo'
                tableName='vw_pedido_despacho'
                fields={['pedido_codigo', 'pedido_razon_social', 'ruta']}
                primaryField='pedido_codigo'
                aliases={[{ value: 'ID', size: 2 }, { value: 'Nombre', size: 6 }, { value: 'Ruta', size: 4 }]}
                size='xx'
                searchField='pedido_razon_social'
                placeHolder='Seleccionar Pedido'
                onSelect={this.handleChange}
                value={this.state.fields["pedidoCodigo"]}
                isView={true}
                formErrors={this.state.formErrors['pedidoCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="vehiculoCodigoCargue"
                tableName='vw_vehiculo_activo'
                fields={['vehiculo_codigo', 'vehiculo_placa', 'conductor', 'configuracion']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Placa', size: 3 }, { value: 'Conductor', size: 6 }, { value: 'Configuracion', size: 2 }]}
                size='xx'
                primaryField='vehiculo_codigo'
                searchField='vehiculo_placa'
                placeHolder='Seleccionar VehiculoCargue'
                onSelect={this.handleChange}
                value={this.state.fields["vehiculoCodigoCargue"]}
                formErrors={this.state.formErrors['vehiculoCodigoCargue']}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoCargue"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroCargue'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoCargue"]}
                size='xx'
                formErrors={this.state.formErrors['terceroCodigoCargue']}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="fordesManifiesto"
                useChildren={true}
                value={this.state.fields["fordesManifiesto"]}
                onChange={this.handleChange}
                namelabel="Manifiesto? "
                formErrors={this.state.formErrors['fordesManifiesto']}
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="vehiculoCodigoViaje"
                tableName='vw_vehiculo_activo'
                fields={['vehiculo_codigo', 'vehiculo_placa', 'conductor', 'configuracion']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Placa', size: 3 }, { value: 'Conductor', size: 6 }, { value: 'Configuracion', size: 2 }]}
                size='xx'
                primaryField='vehiculo_codigo'
                searchField='vehiculo_placa'
                placeHolder='Seleccionar PlacaViaje'
                onSelect={this.handleChange}
                value={this.state.fields["vehiculoCodigoViaje"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoViaje"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                size='xl'
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroViaje'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoViaje"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesPeso" label="Peso (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesPeso"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesFlete" label="Flete (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesFlete"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesAnticipo" label="Anticipo (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesAnticipo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="fordesFactura"
                useChildren={true}
                value={this.state.fields["fordesFactura"]}
                onChange={this.handleChange}
                namelabel="FACTURA? "
                formErrors={this.state.formErrors['fordesFactura']}
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesConsecutivoManual" label="Remesa Consecutivo Manual (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesConsecutivoManual"]}
                error={this.state.formErrors['fordesConsecutivoManual'].error}
                helperText={this.state.formErrors['fordesConsecutivoManual'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesFechaDescargue"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesFechaDescargue"]}
                type="date"
                label="Fecha Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="fordesClienteDocumento" label="Documento Cliente" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fieldsConsulta["fordesClienteDocumento"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}  className={this.props.classes.gridForm}>
              <Link to={{ pathname:"https://muisca.dian.gov.co/WebRutMuisca/DefConsultaEstadoRUT.faces"}} target="_blank"><Chip
                  label="CONSULTAR RUT"
                  clickable
                  className={this.props.classes.chip}
                  color="primary"
                /></Link> 
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="fordesRut"
                useChildren={true}
                value={this.state.fields["fordesRut"]}
                onChange={this.handleChange}
                namelabel="RUT? "
                formErrors={this.state.formErrors['fordesRut']}
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={10} className={this.props.classes.gridForm}>
              <TextField id="fordesObservacion" label="Observacion (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fordesObservacion"]}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="8"
                cols="80"
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              {(this.state.fields.ordcarCodigo !== null) ?

                (<Link to={this.getEditUrl(this.state.fields["ordcarCodigo"], 'ordencargue')}><Chip
                  label={"Orden Cargue:" + this.state.fields["ordcarCodigo"]}
                  clickable
                  className={this.props.classes.chip}
                  color="primary"
                /></Link>) : ''
              }
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              {(this.state.fields.manifiestoCodigo !== null) ?
                (<Link to={this.getEditUrl(this.state.fields["manifiestoCodigo"], 'manifiesto')}><Chip
                  label={"Manifiesto:" + this.state.fields["manifiestoCodigo"]}
                  clickable
                  className={this.props.classes.chip}
                  color="primary"
                /></Link>) : ''
              }
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              {(this.state.fields.facturaCodigo !== null) ?
                (<Link to={this.getEditUrl(this.state.fields["facturaCodigo"], 'factura')}><Chip
                  label={"Factura:" + this.state.fields["facturaCodigo"]}
                  clickable
                  className={this.props.classes.chip}
                  color="primary"
                /></Link>) : ''
              }
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          primaryFields={['fordesCodigo']}
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper>
    );
  }
}

FormDespacho.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(FormDespacho)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;