import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { saveCustom } from './../../../../Actions/General'
import { errorSetter } from '../../../../Libs';
import PSelect from '../../../GeneralUI/PSelect'
import to from 'await-to-js';
import _ from 'lodash';
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class IndicadoresComerciales extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    result: {     
    },
    filters: {
      TIPO_INFORME:null,
      FECHA_INICIAL_SERVICIO:null,
      FECHA_FINAL_SERVICIO:null
    },
    tipoGrafico:'Line'
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    await saveCustom({ values: this.state.filters }, '/processes/getindicador').then(result => {

      this.setState({ result });
      
    }).catch(error => {
      this.props.enqueueSnackbar(error.errorMessage , { variant: 'error'});
    });

  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  handleChange = (event) => {

    let { filters } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      filters[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      filters[event.target.id] = event.target.value;
    } else {
      filters[event.target.id] = event.target.value.toUpperCase();
    }

    if (filters[event.target.id] === '') {
      filters[event.target.id] = null;
    }

    if (event.target.id === 'TIPO_INFORME') {
      this.state.result.data=null;
      if(filters[event.target.id]=='TOTAL VENTAS'){
        this.state.tipoGrafico='Line';
      }
      if(filters[event.target.id]=='OTRO'){
        this.state.tipoGrafico='Bar';
      }
      if(filters[event.target.id]=='TOTAL PEDIDOS'){
        this.state.tipoGrafico='Pie';
      }
    }

    this.setState(filters);
  }


  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
            Indicadores Comerciales 
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>   
            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                  id="TIPO_INFORME"
                  useChildren={true}
                  value={this.state.filters["TIPO_INFORME"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Informe"
                >
                <option value="TOTAL VENTAS">TOTAL VENTAS</option>
                <option value="TOTAL PEDIDOS">TOTAL PEDIDOS</option>
              </PSelect>
            </Grid>  
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_INICIAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.filters["FECHA_INICIAL_SERVICIO"]}
                type="date"
                label="FechaServicio Inicial"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_FINAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.filters["FECHA_FINAL_SERVICIO"]}
                type="date"
                label="FechaServicio Final"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
        </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <Row>
          <Col xs={12} sm={12} md={8}>
          <Card className="card-chart">
              <CardHeader>
                <CardTitle>Resultado:</CardTitle>
              </CardHeader>
              <CardBody>
                {(this.state.result.data) && this.state.tipoGrafico=='Line'?
                <Line
                  data={this.state.result.data}
                  options={this.state.result.options}
                  width={500}
                  height={100}
                />:null
                }

                {(this.state.result.data) && this.state.tipoGrafico=='Pie'?
                <Pie
                  data={this.state.result.data}
                  options={this.state.result.options}
                  width={500}
                  height={100}
                />:null
                }

                {(this.state.result.data) && this.state.tipoGrafico=='Bar'?
                <Bar
                  data={this.state.result.data}
                  options={this.state.result.options}
                  width={500}
                  height={100}
                />:null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Paper>
    );
  }
}

IndicadoresComerciales.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(IndicadoresComerciales)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;