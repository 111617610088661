import {
  api
} from '../Libs';
import {
  FORM as ENDPOINTS,
  FAVORITES
} from '../Config/endpoints';
import _ from 'lodash';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

const {
  SAVE,
  SEARCH,
  PSELECT,
  EDIT,
  REMOVE
} = ENDPOINTS;
const {
  GET,
  DELETE,
  ADD
} = FAVORITES;

export const getFavorites = () => {
  return new Promise((resolve, reject) => {
    if (!cookie.get('crmpymes-token')) {
      return resolve();
    };

    api.doRequest({
      endpoint: GET.url,
      method: GET.method
    }, {}).then(data => {
      //cambiar
      resolve(data.rows);
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
}

export const deleteFavorite = (id) => {
  return new Promise((resolve, reject) => {
    if (!cookie.get('crmpymes-token')) {
      return resolve();
    };

    api.doRequest({
      endpoint: DELETE.url,
      method: DELETE.method
    }, {
      usupagfavCodigo: id
    }).then(data => {
      //cambiar
      resolve();
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
}

export const saveFavorite = (id) => {
  return new Promise((resolve, reject) => {
    api.doRequest({
      endpoint: ADD.url,
      method: ADD.method
    }, {
      paginaCodigo: id
    }).then(() => {
      //cambiar
      resolve({})
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
};

export const searchPage = (ruta, limit = 10) => {
  return new Promise((resolve, reject) => {
    api.doRequest({
      endpoint: SEARCH.url('paginas'),
      method: SEARCH.method
    }, {
      filters: {
        paginaRuta: {
          like: `%${ruta.trim()}%`
        }
      },
      options: {
        limit
      }
    }).then(data => {
      //cambiar
      resolve(_.map(data.rows, row => {
        return {
          label: row.paginaRuta,
          id: row.paginaCodigo
        }
      }))
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
}

export const handlerPselect = (params, moduleName) => {
  return new Promise((resolve, reject) => {
    api.doRequest({
      endpoint: PSELECT.url(moduleName),
      method: PSELECT.method
    }, params).then(data => {
      //cambiar
      resolve(data)
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
};

export const getDocument = (params) => {
  return new Promise((resolve, reject) => {
    let filter = {};
    filter[params.fieldName] = params.documentId;

    api.doRequest({
      endpoint: SEARCH.url(params.moduleName),
      method: SEARCH.method
    }, {
      filters: filter,
      isView: params.isView,
      options: {
        limit: params.Limit > 0 ? params.Limit : 1
      }
    }).then(data => {
      resolve(_.get(data, params.Limit > 0 ? 'rows' : 'rows[0]'));
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
};

export const save = (params, moduleName, primaryFields) => {
  return new Promise((resolve, reject) => {
    let primaryMatches = 0;
    _.forEach(primaryFields, field => {
      if (_.get(params, field)) {
        ++primaryMatches;
      }
    });

    if (primaryMatches === _.get(primaryFields, 'length')) {
      return edit(params, moduleName, primaryFields).then(resolve).catch(reject);
    }

    deletePrivateFields(params, primaryFields);
    api.doRequest({
      endpoint: SAVE.url(moduleName),
      method: SAVE.method
    }, params).then(data => {
      //cambiar
      resolve(data)
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
};

/*export const saveCustom = (params, endPoint) => {
  return new Promise((resolve, reject) => {
    const keys = _.keys(params);
    let endpointData = params;
    let isFileUpload = false;

    _.each(keys, key => {
      console.log(params[key]);
      if (params[key] && params[key] instanceof File) {
        alert('llego');
        isFileUpload = true;
      }
    });

    if (isFileUpload) {
      endpointData = new FormData();
      _.each(keys, key => {
        endpointData.append(key, params[key]);
      });
    }

    api.doRequest({
      endpoint: endPoint,
      method: 'POST'
    }, endpointData).then(data => {
      //cambiar
      resolve(data)
    }).catch(error => {
      //cambiar
      //console.log('General.js -- 185 > error === ', error);
      reject(error);
    });
  });
}*/

export const saveCustom = (params, endPoint) => {
  return new Promise((resolve, reject) => {
    if (!params || typeof params !== 'object') {
      return reject(new Error('Los parámetros no son válidos.'));
    }

    const formatDate = (date) => (date instanceof Date ? date.toISOString() : date);

    // Detectar si hay archivos en los parámetros
    const isFileUpload = Object.values(params).some(value => value instanceof File);
    
    // Si hay archivos, usamos FormData; si no, enviamos JSON normal
    const endpointData = isFileUpload ? new FormData() : { ...params };

    Object.keys(params).forEach(key => {
      const value = params[key];

      if (isFileUpload) {
        if (typeof value === 'object' && value !== null) {
          if (key === 'fields') {
            Object.keys(value).forEach(fieldKey => {
              endpointData.append(`fields[${fieldKey}]`, value[fieldKey] instanceof Date ? formatDate(value[fieldKey]) : value[fieldKey]);
            });
          } else if (key !== 'csvFile') {
            endpointData.append(key, JSON.stringify(value));
          } else {
            endpointData.append(key, value);
          }
        } else {
          endpointData.append(key, value);
        }
      } else {
        // Convertir fechas a ISO en JSON normal
        endpointData[key] = value instanceof Date ? formatDate(value) : value;
      }
    });

    api.doRequest(
      {
        endpoint: endPoint,
        method: 'POST'
      },
      endpointData
    )
      .then(resolve)
      .catch(reject);
  });
};

export const remove = (params, moduleName) => {
  return new Promise((resolve, reject) => {
    api.doRequest({
      endpoint: REMOVE.url(moduleName),
      method: REMOVE.method
    }, params).then(data => {
      //cambiar
      resolve(data)
    }).catch(error => {
      //cambiar
      //console.log('General.js -- 185 > error === ', error);
      reject(error);
    });
  });
}

export const autoCompleteSearch = (searchValue, searchField, fields, moduleName, limit, skip, isView, additionalFilters) => {
  return new Promise((resolve, reject) => {
    let filter = {};

    if (additionalFilters) {
      _.each(_.keys(additionalFilters), key => {
        if (!isView) {
          filter[key] = additionalFilters[key];
        } else {
          //filter[_.snakeCase(key)] = additionalFilters[key];
          filter[key] = additionalFilters[key];

        }
      });
    }

    if (_.isString(searchValue)) {
      filter[searchField] = {
        like: `${searchValue.trim()}%`
      };
    } else {
      filter[searchField] = searchValue;
    }

    api.doRequest({
      endpoint: SEARCH.url(moduleName),
      method: SEARCH.method
    }, {
      isView,
      fields,
      filters: filter,
      options: {
        limit,
        skip
      }
    }).then(data => {
      resolve(data);
    }).catch(reject);
  });
};

export const search = (params, endpoint) => {
  return api.doRequest({
    endpoint,
    method: 'POST'
  }, params);
};


function deletePrivateFields(fields, primaryFields) {
  delete fields.createdAt;
  delete fields.updatedAt;
  delete fields.usuarioCodigoCreadoPor;
  delete fields.usuarioCodigoModificadoPor;
  delete fields.origen;
  delete fields.destino;
  delete fields.pedido_codigo;
  delete fields.pedido_razon_social;
  delete fields.ruta;
  delete fields.pedido_estado;
  delete fields.porcentajeSeguro;

  delete fields.uninegNombre;
  delete fields.tipserNombre;
  delete fields.comercialNombreCompleto;
  delete fields.tiempoFaltante;


  _.forEach(primaryFields, field => delete fields[field]);
}

function edit(params, moduleName, primaryFields) {
  return new Promise((resolve, reject) => {
    deletePrivateFields(params);

    let filters = {};
    _.forEach(primaryFields, field => {
      filters[field] = _.clone(params[field]);
      delete params[field];
    });

    api.doRequest({
      endpoint: EDIT.url(moduleName),
      method: EDIT.method
    }, {
      filters,
      update: params
    }).then(data => {
      //cambiar
      resolve(data)
    }).catch(error => {
      //cambiar
      reject(error);
    });
  });
}